import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import DermatologyRoll from '../../components/DermatologyRoll';
import ShareInfomationPageTemplate from '../../templates/share-infomation-page';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <>
        <Layout>
          <div className="c-bread">
            <div className="c-bread__inner">
              <div className="c-bread__item">
                <Link className="c-bread__link" to={'/'}>
                  <div className="c-bread__text">トップ</div>
                </Link>
              </div>
              <div className="c-bread__item">
                <div className="c-bread__text">保険診療</div>
              </div>
            </div>
          </div>

          <section className="l-section-lv2 l-main-content-hero -medium-hero">
            <div className="l-main-content-hero__inner">
              <DermatologyRoll />
            </div>
          </section>

          <ShareInfomationPageTemplate />
        </Layout>
      </>
    );
  }
}
